.level {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid rgba($color: #000000, $alpha: 0.1);
  color: #333;
  background-color: #f0f0f0;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.levelWithIcon {
  font-size: 26px;
}

.level.completed {
  // background-color: rgba(255, 255, 0, 0.1);
  border-color: var(--neon-color);
  // box-shadow: 0 0 12px 3px var(--neon-shadow);
}

.level:hover {
  background-color: #007aff;
  color: white;
}

.dragHandle {
  cursor: grab;
}
