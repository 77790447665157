.skillContent {
  overflow: hidden;
}

.skillLevels {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  align-items: center;
  row-gap: 10px;
}

.levelItem {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.dragging {
  opacity: 0.8;
  transform: scale(1.02);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.levelDivider {
  width: 26px;
  height: 2px;
  background-color: rgba(84, 77, 31, 0.2);
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;
}

.levelDivider.completed {
  background-color: var(--neon-color);
  // box-shadow: 0 0 10px 2px var(--neon-shadow);
}

@media (max-width: 430px) {
  .skillLevels {
    justify-content: center;
  }
}
