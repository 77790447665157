.skill {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.3s;
}

.skill:hover {
  background-color: #f9f9f9;
}

.skillHeader {
  width: 100%;
  gap: 20px;
  display: flex;
  align-items: center;
}

.skillName {
  font-size: 20px;
  font-weight: 600;
  color: #333;
}

.skillContext {
  display: flex;
  align-items: center;
}

.skillMenu {
  width: auto;
}

/* Стили для иконки */
.generateIcon {
  color: #88c0d0;
  font-size: 28px;
  cursor: pointer;
  transition:
    transform 0.2s,
    color 0.3s;
}

.generateIcon:hover {
  transform: scale(1.2);
  color: #a3be8c;
}

.addCommentIcon {
  color: #88c0d0;
  font-size: 28px;
  cursor: pointer;
  transition:
    transform 0.2s,
    color 0.3s;

  &:hover {
    transform: scale(1.2);
  }
}

.editIcon {
  color: #1976d2;
  font-size: 28px;
  cursor: pointer;
  transition:
    transform 0.2s,
    color 0.3s;

  &:hover {
    transform: scale(1.2);
  }
}

.deleteIcon {
  color: #ff4757;
  font-size: 28px;
  cursor: pointer;
  transition:
    transform 0.2s,
    color 0.3s;

  &:hover {
    transform: scale(1.2);
  }
}

.completedLevel {
  font-size: 14px;
  color: #555;
}

.dragHandle {
  display: flex;
  align-items: center;
  cursor: grab;
  margin-right: 8px;
}

.tagsContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.tagChip {
  margin-right: 4px;
  margin-bottom: 4px;
}

.addTag {
  display: flex;
  align-items: center;
}

.addTagInput {
  width: 20px;
  border: none;
  background: transparent;
  outline: none;
  transition: width 0.3s ease;
  padding: 2px;
  font-size: 0.9rem;
}

/* Делает placeholder незаметным */
.addTagInput::placeholder {
  color: transparent;
}

/* При фокусе инпут расширяется и становится заметным */
.addTagInput:focus {
  width: 100px;
  border-bottom: 1px solid #000;
  color: #000;
  background: #fff;
  /* placeholder можно сделать видимым при фокусе, если требуется */
}

button.optionsButton {
  margin-left: auto;
}

@media (max-width: 430px) {
  .skill {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .skillName {
    font-size: 16px;
  }
}
