.profile {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 8px 20px;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgb(0 0 0 / 10%);
  background-color: white;

  &:hover [class*='Characteristics_actions'] {
    display: block;
  }

  &__hint {
    font-weight: normal;
  }

  &__avatar {
    display: flex;
    // align-items: center;
    margin-right: 5px;
  }

  &__avatar-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 3px solid #e0e0e0;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  }

  &__info {
    flex: 2;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    outline: none;
    transition: border-color 0.2s;

    &:focus {
      border-color: #007aff;
    }
  }

  .profile__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    color: #333;
    height: 28px;

    .MuiInputBase-root {
      padding: 0;
      margin: 0;
    }

    .MuiInputBase-input {
      padding: 0;
      margin: 0;
    }

    // .MuiIconButton-root {
    //   margin-left: 10px;
    // }
  }

  &__edit-icon {
    margin-left: 10px;
  }
}

@media (max-width: 430px) {
  .profile {
    width: 100%;

    [class*='Characteristics_actions'] {
      display: block;
    }

    &__info {
      flex-wrap: nowrap;
      padding: 0;
    }

    &__avatar-image {
      width: 120px;
      height: 120px;
    }

    .profile__item {
      font-size: 15px;
    }
  }
}
