:root {
  
  /* --neon-color: #00FFFF;
  --neon-shadow: #00FFFFAA; */
  
  --neon-color: #00FF00;
  --neon-shadow: #00FF00AA; 

  

  /* --neon-color: #FFFF00;
  --neon-shadow: #FFFF00AA;  */
}
body {
  /* background-color: black; */
  background-color: #f9f9f9;
  /* color: #fff; */
}

