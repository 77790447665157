.menu {
  min-width: 300px;
  width: 300px;
  max-height: 400px;
}

.menuItem {
  width: 300px;
  &:last-child {
    margin-bottom: 50px;
  }
}

.link {
  text-decoration: none;
  color: black;
}
