@import 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@300..800&display=swap';

.gameProfile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;

  .skills {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
  }

  .menu {
    display: flex;
    position: fixed;
    top: 10px;
    right: 10px;
  }

  .addSkillButton {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #218838;
    }
  }

  .addLevelButton {
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    transition: background-color 0.3s;

    &:hover {
      background-color: #0056b3;
    }
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);

    .modalContent {
      background: white;
      padding: 20px;
      border-radius: 10px;
      width: 90%;
      max-width: 400px;
      position: relative;
    }
  }
}

body {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  box-sizing: inherit;
  outline: none;
}

.profile {
}
